// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "q_qQ d_gr d_cq";
export var heroHeaderCenter = "q_gs d_gs d_cq d_dt";
export var heroHeaderRight = "q_gt d_gt d_cq d_dv";
export var heroParagraphLeft = "q_qR d_gn d_ct";
export var heroParagraphCenter = "q_gp d_gp d_ct d_dt";
export var heroParagraphRight = "q_gq d_gq d_ct d_dv";
export var heroBtnWrapperLeft = "q_qS d_d0 d_dZ d_t d_bx d_bD";
export var heroBtnWrapperCenter = "q_qT d_d1 d_dZ d_t d_bx d_bB";
export var heroBtnWrapperRight = "q_qV d_d2 d_dZ d_t d_bx d_bF";
export var overlayBtnWrapper = "q_qW d_gm d_Y d_5 d_6 d_7 d_bl d_b9";
export var design4 = "q_qX d_gl d_Y d_5 d_6 d_bl";
export var heroExceptionSmall = "q_qY r_qY";
export var heroExceptionNormal = "q_qZ r_qZ";
export var heroExceptionLarge = "q_q0 r_q0";
export var Title1Small = "q_q1 r_q1 r_q7 r_q8";
export var Title1Normal = "q_q2 r_q2 r_q7 r_q9";
export var Title1Large = "q_q3 r_q3 r_q7 r_rb";
export var BodySmall = "q_q4 r_q4 r_q7 r_rr";
export var BodyNormal = "q_q5 r_q5 r_q7 r_rs";
export var BodyLarge = "q_q6 r_q6 r_q7 r_rt";