// extracted by mini-css-extract-plugin
export var lbContainerOuter = "t_sC";
export var lbContainerInner = "t_sD";
export var movingForwards = "t_sF";
export var movingForwardsOther = "t_sG";
export var movingBackwards = "t_sH";
export var movingBackwardsOther = "t_sJ";
export var lbImage = "t_sK";
export var lbOtherImage = "t_sL";
export var prevButton = "t_sM";
export var nextButton = "t_sN";
export var closing = "t_sP";
export var appear = "t_sQ";