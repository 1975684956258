// extracted by mini-css-extract-plugin
export var alignDiscLeft = "p_qh d_fm d_bD d_ds";
export var alignLeft = "p_pp d_fm d_bD d_ds";
export var alignDiscCenter = "p_qj d_fn d_bB d_dt";
export var alignCenter = "p_bM d_fn d_bB d_dt";
export var alignDiscRight = "p_qk d_fp d_bF d_dv";
export var alignRight = "p_pq d_fp d_bF d_dv";
export var footerContainer = "p_ql d_dT d_bT";
export var footerContainerFull = "p_qm d_dR d_bT";
export var footerHeader = "p_kb d_kb";
export var footerTextWrapper = "p_qn d_t";
export var footerDisclaimerWrapper = "p_kj d_kj d_ch";
export var badgeWrapper = "p_qp d_t d_bx d_bM d_bC d_bG";
export var footerDisclaimerRight = "p_qq undefined";
export var footerDisclaimerLeft = "p_qr undefined";
export var verticalTop = "p_qs d_bx d_bG d_bL d_bJ";
export var firstWide = "p_qt";
export var disclaimer = "p_qv d_bx d_bG";
export var socialDisclaimer = "p_qw";
export var left = "p_qx";
export var wide = "p_qy d_cw";
export var right = "p_qz d_bH";
export var line = "p_fh d_fj d_cs";
export var badgeDisclaimer = "p_qB d_bz d_bM d_bG";
export var badgeContainer = "p_qC d_bx d_bF d_bM";
export var badge = "p_qD";
export var padding = "p_qF d_c5";
export var end = "p_qG d_bF";
export var linkWrapper = "p_qH d_dy";
export var link = "p_mz d_dy";
export var colWrapper = "p_qJ d_cv";
export var consent = "p_f d_f d_bz d_bM";
export var media = "p_qK d_bv d_dw";
export var itemRight = "p_qL";
export var itemLeft = "p_qM";
export var itemCenter = "p_qN";
export var exceptionWeight = "p_qP r_rz";